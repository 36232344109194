import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'not-found-page',
  templateUrl: './not-found-page.html'
})
export class NotFoundPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
