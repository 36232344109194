import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'main-tabs',
  templateUrl: './main-tabs.html'
})
export class MainTabsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
